import React, { useEffect, useRef, useState } from 'react';
import { Global } from '@emotion/react';
import globalStyles from '@globalStyles';
import styles from './styles';
import { useTheme } from '@mui/styles';
import Header from '@components/common/Layouts/Basic/Header';
import { HeaderHeightContext } from '@helpers/contexts';

const Basic: React.FC<{
	HeaderProps?: {
		inversed?: boolean;
		contained?: boolean;
		simpleLogo?: boolean;
	};
	disableGutterTop?: boolean;
	disableGutterBottom?: boolean;
	flush?: boolean;
	center?: boolean;
	header?: boolean;
}> = ({
	HeaderProps,
	header = true,
	center,
	children,
	flush,
	disableGutterTop,
	disableGutterBottom,
}) => {
	const theme = useTheme();
	const $header: any = useRef(null);
	const [headerHeight, setHeaderHeight] = useState(null);
	const isBrowser = typeof window !== 'undefined';

	const handleHeaderHeight = () => {
		setHeaderHeight($header?.current?.offsetHeight ?? null);
	};

	useEffect(() => {
		if (isBrowser) {
			window.addEventListener('resize', handleHeaderHeight);
		}
		handleHeaderHeight();

		return () => {
			window.removeEventListener('resize', handleHeaderHeight);
		};
	}, []);

	return (
		<HeaderHeightContext.Provider value={{ height: headerHeight }}>
			<Global styles={globalStyles(theme)} />
			<div css={styles(theme)} className={`layout ${center ? 'layout-center' : ''}`}>
				{header && <Header ref={$header} {...(HeaderProps ?? {})} />}
				<main
					className={`content-wrapper`}
					style={
						flush
							? {
									marginTop: `-${headerHeight}px`,
							  }
							: undefined
					}
				>
					<div
						className={`content ${
							disableGutterTop ? 'content-disable-gutter-top' : ''
						} ${disableGutterBottom ? 'content-disable-gutter-bottom' : ''}`}
					>
						{children}
					</div>
					{/*<Footer />*/}
				</main>
			</div>
		</HeaderHeightContext.Provider>
	);
};

export default Basic;
