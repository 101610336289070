import React, { useMemo } from 'react';
import styles from './styles';
import { AppBar, Avatar, Button, Container, Grid, Toolbar, Typography, Link } from '@mui/material';
import { useTheme } from '@mui/styles';
import { Link as RouterLink } from 'gatsby';
import { useSelector } from 'react-redux';
import { UserState } from '@store/user/types';
import LogoBlack from '@assets/logo/logo-black.inline.svg';
import LogoWhite from '@assets/logo/logo-white.inline.svg';
import { DarkModeState } from '@store/darkMode/types';
import { useLocation } from '@reach/router';
import { USER_AVATAR } from '@helpers/api';

const Content: React.FC<{ contained?: boolean }> = ({ contained, children }) =>
	contained ? <Container>{children}</Container> : <>{children}</>;

const Header: React.FC<{
	simpleLogo?: boolean;
	contained?: boolean;
	inversed?: boolean;
	innerRef?: any;
}> = ({ simpleLogo, contained = true, inversed, innerRef }) => {
	const location = useLocation();
	const theme = useTheme();
	const { user, darkMode } = useSelector(({ user, darkMode }) => ({
		user,
		darkMode,
	}));

	return (
		<div ref={innerRef} css={styles(theme)}>
			<AppBar
				position="static"
				color="transparent"
				className={`header ${inversed ? 'header-inversed' : ''}`}
			>
				<Toolbar>
					<Content contained={contained}>
						<Grid
							justifyContent="space-between"
							container
							spacing={2}
							alignItems="center"
						>
							<Grid item>
								<RouterLink to="/">
									<Typography
										className="header-logo"
										variant="h6"
										noWrap
										component="h1"
									>
										{inversed ? <LogoWhite /> : <LogoBlack />}
										{simpleLogo && (
											<span style={{ color: inversed ? '#000' : '#fff' }}>
												Wave Card
											</span>
										)}
									</Typography>
								</RouterLink>
							</Grid>

							<Grid item className="navigation">
								<Grid
									container
									spacing={10}
									justifyContent="flex-end"
									alignItems="center"
								>
									<Grid item className="navigation-item navigation-item-user">
										{!!user ? (
											<RouterLink
												className="header-account"
												to="/dashboard/account"
											>
												<Avatar
													className="header-account-avatar"
													alt={`${user?.firstName ?? ''} ${
														user?.lastName ?? ''
													}`}
													src={USER_AVATAR(user?._id)}
												/>
												<Typography className="header-account-name">{`${
													user?.firstName ?? ''
												} ${user?.lastName ?? ''}`}</Typography>
											</RouterLink>
										) : location.pathname === '/login' ? (
											<Typography>
												Don’t have an account?{' '}
												<Link component={RouterLink} to="/register">
													Get started
												</Link>
											</Typography>
										) : location.pathname === '/register' ? (
											<Typography>
												Already have an account?{' '}
												<Link component={RouterLink} to="/login">
													Login
												</Link>
											</Typography>
										) : (
											<Button component={RouterLink} to="/login">
												Login
											</Button>
										)}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Content>
				</Toolbar>
			</AppBar>
		</div>
	);
};

export default React.forwardRef((props, ref) => <Header {...props} innerRef={ref} />);
