import { css } from '@emotion/react';

export default (theme) => css`
	&.layout {
		display: flex;
		flex-direction: column;

		&.layout-center {
			.content {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
		}

		.content-wrapper {
			flex-grow: 1;
			display: flex;
			flex-direction: column;

			.content {
				flex-grow: 1;
				padding: ${theme.spacing(5, 0)};

				&.content-disable-gutter-bottom {
					padding-bottom: 0;
				}

				&.content-disable-gutter-top {
					padding-top: 0;
				}
			}
		}
	}
`;
