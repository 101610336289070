import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export default (theme: Theme) => css`
	.header {
		box-shadow: none;
		padding: ${theme.spacing(3, 1.5)};
		background-image: none;

		&.header-inversed {
			.header-logo {
				color: ${theme.palette.common.white};
			}
		}

		.header-logo {
			display: flex;
			align-items: center;

			svg {
				width: 40px;
				height: auto;

				* {
					fill: ${theme.palette.mode === 'light'
						? theme.palette.common.black
						: theme.palette.common.white};
				}
			}

			span {
				margin-left: ${theme.spacing(2)};
				text-transform: uppercase;
				font-weight: 700;
				letter-spacing: 0.12em;
				color: ${theme.palette.common.white};
			}
		}

		.header-account {
			display: flex;
			align-items: center;

			.header-account-avatar {
				height: 30px;
				width: 30px;
				margin-right: ${theme.spacing(1.5)};
			}

			.header-account-name {
				font-weight: bold;
				color: ${theme.palette.common.white};
				font-size: ${theme.typography.fontSize}px;
			}
		}
	}
`;
